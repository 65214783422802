<template>
  <div class="q-ma-sm">
    <q-card bordered class="my-card shadow-7 q-ma-md" style="max-width: 500px">
      <q-card-section class="q-ma-none q-pa-xs q-pl-lg">
        <div class="text-left q-ma-none q-pt-md q-pa-none">
          {{ settings.system_name }}
        </div>
      </q-card-section>
      <q-card-section class="q-ma-none q-pa-none q-pl-lg">
        <div
          class="main-name text-h4 text-weight-bold text-left q-ma-none q-pb-md q-pa-none"
        >
          {{ user.name }}
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import settings from "@/mixins/settings";

export default {
  mixins: [settings],

  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
  box-shadow: 0px 4px 8px 0px #00000026;
  color: #282828;
}

.main-name {
  word-break: break-all;
}
</style>
